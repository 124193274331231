import axios from "axios";
import { CONSTANTS } from "./constants";

export const getTwitchToken = async () => {
  try {
    const encodedCredentials = btoa(
      `${CONSTANTS.TWITCH_ID}:${CONSTANTS.TWITCH_SECRET}`
    );

    const req = await axios.post(
      "https://id.twitch.tv/oauth2/token",
      {
        client_id: CONSTANTS.TWITCH_ID,
        client_secret: CONSTANTS.TWITCH_SECRET,
        grant_type: "client_credentials",
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Basic ${encodedCredentials}`,
        },
      }
    );

    return req?.data?.access_token;
  } catch (error) {
    console.error(error);
  }
};
