export const CONSTANTS = {
  REGEX: {
    instagram: /(?:https?:\/\/(?:www\.)?)?instagram\.com\/([^/?#&]+)\/?/,
    tiktok: /(?:https?:\/\/(?:www\.)?(?:vm\.)?)?tiktok\.com\/@?([^/?#&]+)\/?/,
    twitch: /(?:https?:\/\/(?:www\.)?)?twitch\.tv\/([^/?#&]+)\/?/,
    twitter: /(?:https?:\/\/(?:www\.)?)?twitter\.com\/([^/?#&]+)\/?/,
    youtube:
      /(?:https?:\/\/(?:www\.)?)?(?:youtube.com|youtu.be)\/(c\/|channel\/|user\/|@)?([a-zA-Z0-9\-_]{1,})\/?/,
  },
  TWITCH_ID: "rhcse8oahcl2m45jip4xtwgyegntoz",
  TWITCH_SECRET: "rxi3e4r2iegjc8a79ybotdvip99vti",
  YOUTUBE_APIKEY: "AIzaSyBCNV-MUjLhZCw4C0arZFFHhgE8WQrxPjo",
};
