import axios from "axios";
import { CONSTANTS } from "./constants";

const getTwitchCleanUsername = (user) => {
  const [, match] = user.match(CONSTANTS.REGEX.twitch) || [];
  return (match ?? user.replaceAll(" ", "").replaceAll("@", "")).toLowerCase();
};

export const getTwitchUsersData = async ({ users, twitchToken }) => {
  try {
    const limit = 100;
    let i,
      j,
      chunk = limit;
    const headers = {
      Authorization: "Bearer " + twitchToken,
      "Client-ID": CONSTANTS.TWITCH_ID,
    };

    for (i = 0, j = users.length; i < j; i += limit) {
      if (i + limit >= j) chunk = j - i;

      const chunkUsers = users.slice(i, i + chunk);
      const loginListNames = chunkUsers
        .map(
          ({ usuario }, i) =>
            `${i === 0 ? "?" : "&"}login=${getTwitchCleanUsername(usuario)}`
        )
        .join("");

      const usersUrl = `https://api.twitch.tv/helix/users${loginListNames}`;
      const usersResponse = await axios.get(usersUrl, { headers });
      const userFromApi = usersResponse?.data?.data;

      const loginListNamesForStreams = loginListNames.replaceAll(
        "login=",
        "user_login="
      );

      const streamsUrl = `https://api.twitch.tv/helix/streams${loginListNamesForStreams}`;
      const streamsResponse = await axios.get(streamsUrl, { headers });
      const streamOnline = streamsResponse.data.data;

      if (userFromApi) {
        for (const userWithData of userFromApi) {
          for (const [position, { usuario }] of users.entries()) {
            const userFromArray = getTwitchCleanUsername(usuario);
            const userFromPlatform = userWithData.login;

            if (userFromArray === userFromPlatform) {
              const isOnline = streamOnline.some(
                ({ user_id }) => user_id === userWithData.id
              );

              users[position] = {
                ...users[position],
                twitchData: { ...userWithData, isOnline },
              };
              break;
            }
          }
        }
      }
    }

    return users;
  } catch (error) {
    console.error(error);
  }
};
