// Dependecies
import { memo } from "react";

// Assets
import "./styles.scss";

export const Loader = memo(() => (
  <div className="Loader">
    <div className="texto dark:text-white">Cargando datos...</div>
    <div className="tower">
      <div className="tower__group">
        <div className="tower__brick-layer tower__brick-layer--4">
          <div className="tower__brick tower__brick--0">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--90 tower__brick--red">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--180 tower__brick--orange">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--270 tower__brick--purple">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
        <div className="tower__brick-layer tower__brick-layer--3">
          <div className="tower__brick tower__brick--45 tower__brick--magenta">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--135">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--225 tower__brick--green">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--315 tower__brick--orange">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
        <div className="tower__brick-layer tower__brick-layer--2">
          <div className="tower__brick tower__brick--0 tower__brick--red">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--90 tower__brick--green">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--180 tower__brick--purple">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--270">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
        <div className="tower__brick-layer tower__brick-layer--1">
          <div className="tower__brick tower__brick--45 tower__brick--purple">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--135 tower__brick--magenta">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--225 tower__brick--red">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--315 tower__brick--green">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
        <div className="tower__brick-layer">
          <div className="tower__brick tower__brick--0 tower__brick--move14">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--90 tower__brick--red tower__brick--move13">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--180 tower__brick--orange tower__brick--move16">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--270 tower__brick--purple tower__brick--move15">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
        <div className="tower__brick-layer tower__brick-layer---1">
          <div className="tower__brick tower__brick--45 tower__brick--move10 tower__brick--magenta">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--135 tower__brick--move9">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--225 tower__brick--green tower__brick--move12">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--315 tower__brick--orange tower__brick--move11">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
        <div className="tower__brick-layer tower__brick-layer---2">
          <div className="tower__brick tower__brick--0 tower__brick--red tower__brick--move6">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--90 tower__brick--green tower__brick--move5">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--180 tower__brick--purple tower__brick--move8">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--270 tower__brick--move7">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
        <div className="tower__brick-layer tower__brick-layer---3">
          <div className="tower__brick tower__brick--45 tower__brick--purple tower__brick--move2">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--135 tower__brick--magenta tower__brick--move1">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--225 tower__brick--red tower__brick--move4">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
          <div className="tower__brick tower__brick--315 tower__brick--green tower__brick--move3">
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-side"></div>
            <div className="tower__brick-stud"></div>
            <div className="tower__brick-stud"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
));
