import { CONSTANTS } from "@utils";

const getFinalUrl = (user, platform) =>
  ({
    instagram: `https://instagram.com/${user}`,
    tiktok: `https://tiktok.com/@${user}`,
    trovo: `https://trovo.live/${user}`,
    twitch: `https://twitch.tv/${user}`,
    twitter: `https://twitter.com/${user}`,
    youtube_id: `https://youtube.com/channel/${user}`,
    youtube_user: `https://youtube.com/@${user}`,
  }[platform]);

const getFromYoutube = (user) => {
  const [, matchType, matchUser] = user.match(CONSTANTS.REGEX.youtube) || [];
  const youtubeUrlType = matchType?.includes("channel")
    ? "youtube_id"
    : "youtube_user";
  const finalUser = matchUser || user.replaceAll(" ", "").replaceAll("@", "");

  return [youtubeUrlType, finalUser];
};

const getFromRest = (user, platform) => {
  const [, match] = user.match(CONSTANTS.REGEX[platform]) || [];
  const newUser = match || user.replaceAll(" ", "").replaceAll("@", "");

  return newUser;
};

export const getPlaformUrlFromUser = (user, platform) => {
  let newUrl;

  if (platform === "youtube") {
    const [youtubePlatorm, newUser] = getFromYoutube(user);
    newUrl = getFinalUrl(newUser.toLowerCase(), youtubePlatorm);
  } else {
    const newUser = getFromRest(user, platform);
    newUrl = getFinalUrl(newUser.toLowerCase(), platform);
  }

  return newUrl;
};
