import { getTwitchUsersData, getYouTubeUserData } from "@utils";

export const getUsersPlatformData = async ({ users, twitchToken }) => {
  const twitchStreamers = users.filter(
    ({ plataforma, banneado }) =>
      plataforma.toLowerCase() === "twitch" && banneado === null
  );

  const twitchStreamersWithData = await getTwitchUsersData({
    users: twitchStreamers,
    twitchToken,
  });

  const youtubeStreamers = users.filter(
    ({ plataforma, banneado }) =>
      plataforma.toLowerCase() === "youtube" && banneado === null
  );

  const youtubeStreamersWithData = await getYouTubeUserData({
    users: youtubeStreamers,
  });

  const newUsersWithData = [
    ...twitchStreamersWithData,
    ...youtubeStreamersWithData,
  ].sort(() => 0.5 - Math.random());

  return newUsersWithData;
};
