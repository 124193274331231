// Dependencies
import { useEffect } from "react";
import ReactGA from "react-ga";

// Pages
import { Home } from "@pages";

// Context
import { DataProvider } from "@context";

ReactGA.initialize("UA-40133506-1");

const App = () => {
  useEffect(() => {
    const setDarkModeInitial = () => {
      if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
      )
        document.documentElement.classList.add("dark");
      else document.documentElement.classList.remove("dark");
    };

    setDarkModeInitial();
  }, []);

  return (
    <DataProvider>
      <Home />
    </DataProvider>
  );
};

export default App;
