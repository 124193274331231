// Dependencies
import { useEffect, useState, useMemo, useCallback } from "react";
import ReactGA from "react-ga";

// Components
import { Buscador, Footer, Header, Loader, User } from "@components";

// Utils
import { getTwitchToken, getUsersPlatformData, obtenerSheet } from "@utils";

// Assets
import "./styles.css";
import { useData } from "@context";

export const Home = () => {
  const [streamers, setStreamers] = useState();
  const [filter, setFilter] = useState("");
  const { setTwitchToken } = useData();

  const onType = (text) => setFilter(text);

  const registrarClick = useCallback(
    () =>
      ReactGA.event({
        category: "User",
        action: "Click en añadir a la lista",
      }),
    []
  );

  const renderStreamers = useMemo(
    () =>
      streamers
        ?.filter(
          ({ usuario }) =>
            usuario.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
            filter === ""
        )
        .map((streamer) => <User key={streamer.marcaTemporal} {...streamer} />),
    [filter, streamers]
  );

  useEffect(() => {
    const initialize = async () => {
      ReactGA.pageview(window.location.pathname + window.location.search);

      const streamersList = await obtenerSheet();
      const twitchToken = await getTwitchToken();

      setTwitchToken(twitchToken);

      const newStreamersWithStats = await getUsersPlatformData({
        users: streamersList,
        twitchToken,
      });

      setStreamers(newStreamersWithStats);
    };

    initialize();
  }, [setTwitchToken]);

  return (
    <div className="Home bg-white dark:bg-gray-900 min-h-screen transition delay-400">
      {streamers ? (
        <>
          <Header />
          <section className="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4">
            <Buscador onType={onType} />
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-4">
              {renderStreamers}

              <li className="hover:shadow-lg flex rounded-lg">
                <a
                  className="hover:border-transparent dark:hover:border-purple-500 hover:shadow-xs dark:hover:text-purple-500 w-full flex items-center justify-center rounded-lg border-2 border-dashed border-gray-200 text-sm dark:text-gray-200 font-medium py-4"
                  href="https://forms.gle/yE7TMHe9RCrii3Wb6"
                  onClick={registrarClick}
                  rel="noreferrer"
                  target="_blank"
                  title="Añadir a la lista de streamers"
                >
                  Añadir a la lista
                </a>
              </li>
            </ul>
          </section>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
