import { keysAmigables } from "@utils";

export const obtenerSheet = async () => {
  try {
    // const url = "https://sheet.best/api/sheets/df5643cf-9868-401f-b2cc-075d293961e0"
    const url =
      "https://docs.google.com/spreadsheets/d/1MeAYDKYvaJYREq0ZW6WwN3dzp3zQpbVtGMB-3h18VH4/gviz/tq?tqx=out:json";

    const req = await fetch(url);
    const data = await req.text();
    const json = JSON.parse(data.substr(47).slice(0, -2));
    const tabla = json.table;
    const header = tabla.cols.map(({ label }) => label);
    const datosFiltrados = tabla.rows.map(({ c }) => {
      const newItem = {};

      c.forEach((i, k) => {
        newItem[keysAmigables(header[k])] =
          k !== 0 ? i?.v || null : i?.f || null;
      });

      return newItem;
    });

    return datosFiltrados;
  } catch (e) {
    console.error("obtenerSheet", e);
  }
};
