import axios from "axios";
import { CONSTANTS } from "./constants";

export const getTwitchFollowers = async ({ userId, twitchToken }) => {
  try {
    const headers = {
      Authorization: "Bearer " + twitchToken,
      "Client-ID": CONSTANTS.TWITCH_ID,
    };

    const followsRequest = await axios.get(
      `https://api.twitch.tv/helix/users/follows?to_id=${userId}`,
      { headers }
    );
    const followers = followsRequest?.data?.total;

    return followers;
  } catch (error) {
    console.error(error);
    return null;
  }
};
