// Dependecies
import { memo } from "react";
import cn from "classnames";

// Assets
import "./styles.scss";

export const LoaderSmall = memo(({ className }) => (
  <div className={cn("LoaderSmall", className)}>
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
));
