// Dependecies
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  faDiscord,
  faInstagram,
  faTiktok,
  faTwitch,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useData } from "@context";
import { CONSTANTS, getPlaformUrlFromUser, getTwitchFollowers } from "@utils";
import { LoaderSmall } from "@components";

// Assets
import "./styles.scss";

export const User = memo(
  ({
    // descripcionDelStream,
    discord,
    // genero,
    // imagenDePerfil,
    instagram,
    nombreYApellido,
    // pais,
    plataforma,
    tiktok,
    twitchData,
    twitter,
    usuario,
    youtube,
    youtubeData,
  }) => {
    const [followers, setFollowers] = useState();
    const { twitchToken } = useData();

    const platform = useMemo(() => plataforma.toLowerCase(), [plataforma]);

    const platformIcon = useMemo(
      () =>
        ({
          trovo: faTwitch,
          twitch: faTwitch,
          youtube: faYoutube,
        }[platform]),
      [platform]
    );

    const user = useMemo(() => {
      const [, match] = usuario.match(CONSTANTS.REGEX[platform]) || [];
      const user = match ?? usuario.replaceAll(" ", "").replaceAll("@", "");

      return user;
    }, [platform, usuario]);

    // const obtenerColorplatforma = useMemo(
    //   () =>
    //     ({
    //       trovo: "bg-green-600",
    //       twitch: "bg-purple-600",
    //       youtube: "bg-red-600",
    //     }[platform]),
    //   [platforma]
    // );

    const getLink = useCallback(
      (platformUser, platformToCheck) =>
        getPlaformUrlFromUser(platformUser, platformToCheck),
      []
    );

    // const iconoPais = useMemo(
    //   () =>
    //     ({
    //       uruguay: "🇺🇾",
    //       argentina: "🇦🇷",
    //     }[pais.toLowerCase()] || pais),
    //   [pais]
    // );

    const isTwitch = useMemo(() => platform === "twitch", [platform]);

    const isYouTube = useMemo(() => platform === "youtube", [platform]);

    const hasSocialMedia = useMemo(
      () => instagram || tiktok || twitter || discord,
      [instagram, tiktok, twitter, discord]
    );

    const avatar = useMemo(
      () =>
        isTwitch
          ? twitchData?.profile_image_url
          : youtubeData?.snippet?.thumbnails?.medium?.url,
      [isTwitch, twitchData, youtubeData]
    );

    const isLive = useMemo(
      () => (isTwitch ? twitchData?.isOnline : false),
      [isTwitch, twitchData]
    );

    useEffect(() => {
      const initialize = async () => {
        if (isTwitch) {
          const newTwitchFollowers = await getTwitchFollowers({
            userId: twitchData?.id,
            twitchToken,
          });
          if (newTwitchFollowers) setFollowers(newTwitchFollowers);
        } else setFollowers(youtubeData?.statistics?.subscriberCount);
      };

      initialize();
    }, [isTwitch, twitchData, youtubeData, twitchToken]);

    return (
      <li
        className={cn(
          "User flex flex-col justify-center items-center container mx-auto max-w-sm rounded-lg overflow-hidden shadow-lg my-2 bg-white",
          platform
        )}
      >
        {isLive && (
          <a
            className="online"
            href={getLink(user, platform)}
            rel="noreferrer"
            target="_blank"
            title={user}
          >
            <span>EN DIRECTO</span>
          </a>
        )}
        <div className="image-container">
          {avatar && <img className="img" src={avatar} alt={user} />}
          <div className="user-texts">
            <a
              className="text-white tracking-wide text-xl font-bold hover:underline"
              href={getLink(user, platform)}
              rel="noreferrer"
              target="_blank"
              title={user}
            >
              @{user}
            </a>
            <p className="text-white text-sm">{nombreYApellido}</p>
          </div>
        </div>
        <div className="redes-sociales">
          {hasSocialMedia && (
            <>
              {!isYouTube && youtube && (
                <a
                  className="red-social"
                  href={getLink(youtube, "youtube")}
                  rel="noreferrer"
                  target="_blank"
                  title={instagram}
                >
                  <FontAwesomeIcon icon={faYoutube} className="text-xl" />
                </a>
              )}
              {/* {!isTwitch && youtube && (
                <a
                  className="red-social"
                  href={getLink(youtube, "youtube")}
                  rel="noreferrer"
                  target="_blank"
                  title={instagram}
                >
                  <FontAwesomeIcon icon={faYoutube} className="text-xl" />
                </a>
              )} */}
              {instagram && (
                <a
                  className="red-social"
                  href={getLink(instagram, "instagram")}
                  rel="noreferrer"
                  target="_blank"
                  title={instagram}
                >
                  <FontAwesomeIcon icon={faInstagram} className="text-xl" />
                </a>
              )}
              {tiktok && (
                <a
                  className="red-social"
                  href={getLink(tiktok, "tiktok")}
                  rel="noreferrer"
                  target="_blank"
                  title={tiktok}
                >
                  <FontAwesomeIcon icon={faTiktok} className="text-xl" />
                </a>
              )}
              {twitter && (
                <a
                  className="red-social"
                  href={getLink(twitter, "twitter")}
                  rel="noreferrer"
                  target="_blank"
                  title="Discord"
                >
                  <FontAwesomeIcon icon={faTwitter} className="text-xl" />
                </a>
              )}
              {discord?.includes("http") && (
                <a
                  className="red-social"
                  href={discord}
                  rel="noreferrer"
                  target="_blank"
                  title="Discord"
                >
                  <FontAwesomeIcon icon={faDiscord} className="text-xl" />
                </a>
              )}
            </>
          )}
        </div>

        <div className="stats-container">
          <div className="stat">
            <span className="title">
              {isTwitch ? "Seguidores" : "Suscriptores"}
            </span>
            <span className="value">
              {followers || <LoaderSmall className={platform} />}
            </span>
          </div>
        </div>
        <a
          className="boton-canal"
          href={getLink(user, platform)}
          rel="noreferrer"
          target="_blank"
          title={user}
        >
          Ir al canal
          <FontAwesomeIcon
            icon={platformIcon}
            className="text-2xl text-white"
          />
        </a>
      </li>
    );
  }
);
