// Dependecies
import { memo } from "react";
import ReactGA from "react-ga";

// Components
import { DarkModeToggler } from "@components";

export const Header = memo(() => {
  const registrarClick = () =>
    ReactGA.event({
      category: "User",
      action: "Click en añadir a la lista",
    });

  return (
    <nav className="flex flex-col md:flex-row items-center justify-between flex-wrap bg-purple-700 p-6">
      <div className="flex items-center flex-col flex-shrink-0 text-white mb-3 md:mb-0 md:mr-6">
        <h1 className="font-semibold text-2xl tracking-tight">
          Lista de Streamers de Uruguay 🇺🇾
        </h1>
        <a
          href="https://unilink.uy/dimanacho"
          className="font-semibold text-xs tracking-tight text-right w-full"
        >
          - por <span className="underline">DiMaNacho</span>
        </a>
      </div>
      {/* <div className="block md:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div> */}

      <div className="w-full flex justify-between flex-grow md:items-center md:justify-end md:w-auto">
        <a
          className="hover:bg-purple-200 hover:text-purple-800 group flex items-center rounded-md bg-purple-100 text-purple-600 text-sm font-medium px-4 py-2 order-2 md:order-1"
          href="https://forms.gle/yE7TMHe9RCrii3Wb6"
          onClick={registrarClick}
          rel="noreferrer"
          target="_blank"
          title="Añadir a la lista de streamers"
        >
          <svg
            className="group-hover:text-purple-600 text-purple-500 mr-2"
            width="12"
            height="20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z"
            />
          </svg>
          Añadir a la lista
        </a>
        <DarkModeToggler className="order-1 md:order-2 md:ml-4" />
      </div>
    </nav>
  );
});
