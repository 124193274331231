// Dependecies
import { memo, useCallback } from "react";
import cn from "classnames";

// Hooks
import { useDarkMode } from "@hooks";

export const DarkModeToggler = memo(({ className }) => {
  const [colorTheme, setColorTheme] = useDarkMode();

  const handleClick = useCallback(
    () => setColorTheme(colorTheme === "dark" ? "dark" : "light"),
    [colorTheme, setColorTheme]
  );

  return (
    <button
      className={cn("flex justify-center items-center", className)}
      onClick={handleClick}
    >
      {/* Sol */}
      <span>
        <svg
          className={cn(
            "h-6 w-6 transition duration-400",
            {
              "text-purple-900": colorTheme === "light",
            },
            {
              "text-gray-50": colorTheme === "dark",
            }
          )}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </span>

      {/* Switch */}
      <div
        className={cn(
          "w-14 h-7 flex items-center  rounded-full mx-3 px-1 transition duration-400",
          {
            "bg-purple-900": colorTheme === "light",
          },
          {
            "bg-purple-400": colorTheme === "dark",
          }
        )}
      >
        <div
          className={cn(
            "bg-white w-5 h-5 rounded-full shadow-md transform transition duration-400",
            {
              "translate-x-7": colorTheme === "light",
            }
          )}
        ></div>
      </div>

      {/* Luna */}
      <span>
        <svg
          className={cn(
            "h-6 w-6 transition duration-400",
            {
              "text-gray-50": colorTheme === "light",
            },
            {
              "text-purple-900": colorTheme === "dark",
            }
          )}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </span>
    </button>
  );
});
