import axios from "axios";
import { CONSTANTS } from "./constants";

export const getYouTubeUserData = async ({ users }) => {
  try {
    const limit = 100;
    let i,
      j,
      chunk = limit;

    for (i = 0, j = users.length; i < j; i += limit) {
      if (i + limit >= j) chunk = j - i;

      const chunkUsers = users.slice(i, i + chunk);

      const idList = chunkUsers.map((user) => user.youtubeid).join(",");

      const url = `https://www.googleapis.com/youtube/v3/channels?part=id,snippet,statistics&id=${idList}&key=${CONSTANTS.YOUTUBE_APIKEY}`;
      const response = await axios.get(url);
      const userFromApi = response?.data?.items;

      if (userFromApi) {
        userFromApi.forEach((userWithData) => {
          users.every((user, position) => {
            const userFromArray = user.youtubeid;
            const userFromPlatform = userWithData.id;

            if (userFromArray === userFromPlatform) {
              users[position] = {
                ...users[position],
                youtubeData: userWithData,
              };
              return false;
            }

            return true;
          });
        });
      }
    }

    return users;
  } catch (error) {
    console.error(error);
  }
};
