import { createContext, useContext, useMemo, useState } from "react";

const DataContext = createContext({
  twitchToken: undefined,
  setTwitchToken: () => {},
});

export const DataProvider = ({ children, ...props }) => {
  const [twitchToken, setTwitchToken] = useState();

  const value = useMemo(() => ({ twitchToken, setTwitchToken }), [twitchToken]);

  return (
    <DataContext.Provider value={value} {...props}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
