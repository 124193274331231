// Dependecies
import { memo } from "react";

export const Buscador = memo(({ onType }) => (
  <form className="relative">
    <svg
      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
      fill="currentColor"
      height="20"
      width="20"
    >
      <path
        clipRule="evenodd"
        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        fillRule="evenodd"
      />
    </svg>
    <input
      aria-label="Buscar streamer"
      className="focus:border-purple-500 focus:ring-1 focus:ring-purple-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10"
      onChange={(e) => onType(e.target.value)}
      placeholder="Buscar streamer"
      type="text"
    />
  </form>
));
